.prosemirror-suggestion, .prosemirror-mention-node {
	color: #08c;
	font-weight: bold;
}

.suggestion-item-list {
	background: #fff;
	border: 1px solid #999;
	overflow-y: auto;
	max-height: 300px;

	.suggestion-item-active {
		background: #08c;
		color: #fff;
	}

	.suggestion-item {
		padding: 0.2em 0.4em;
		cursor: pointer;
		display: flex;

		span.glyphicon-user {
			font-size: 1.6em;
			margin: 0.4em;
		}

		.user {
			margin: 0.2em;
		}

		.suggestion-item-username {
			font-size: 11px;
		}
	}
}
